[data-only-init] {
  display: none !important;
}

a, .pointer {
  cursor: pointer;
  transition: opacity 0.5s ease;
  &.pointer-hover:hover {
    opacity: 0.7;
  }
}
a:not([href]).href {
  color: var(--primary);
}
// --primary
.clearfix {
  clear: both;
  content: "";
  display: block;
}
.main_content_wrapper {
  min-height: calc(100vh - 10em);
}

.no-bgimage {
  background-image: none !important;
}

@media (min-width: 1400px) {
  body {
    font-size: 16px;
  }
}
.font-size06 {
  font-size: 0.6em;
}
.font-size07 {
  font-size: 0.7em;
}
.font-size08 {
  font-size: 0.8em;
}
.font-size09 {
  font-size: 0.9em;
}
.font-size12 {
  font-size: 1.2em;
}
.font-size13 {
  font-size: 1.3em;
}
.font-size14 {
  font-size: 1.4em;
}
.font-size15 {
  font-size: 1.5em;
}
.font-size16 {
  font-size: 1.6em;
}
.font-size17 {
  font-size: 1.7em;
}
.thumb-container {
  position: relative;
  .thmb-wrapper-16-9 {
    position: relative;
    &::before {
      display: inline-block;
      content: "";
      padding-top: 53.25%;
    }
    .bg-cont-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@mixin overlay-watched() {
  .overlay-watched{
    display: block;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    transition: opacity 0.5s ease;
    position: absolute;
    background-color: #ddd;
    color: #fff;
    text-align: center;
    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 50%;
    }
  }
}

.overlay_parent {
  @include overlay-watched();
}

.gsa-nav {
  .nav-right-ico-mob {
    top: 20px;
  }
  #nav-search-sw-mob {
    right: 85px;
  }
  #nav-notif-mob {
    right: 112px;
    &.rm-search {
      right: 82px;
    }
    .badge-notif-overlay {
      right: -13px;
      top: -5px;
    }
  }
}
.comment-container {
  .root-form {
    .input-ctrl {
      width: calc(100% - 3.75rem);
      float: left;
      textarea {
        width: 100%;
      }
    }
    .avatar {
      width: 3rem;
      float: left;
      margin-right: 0.625rem;
    }
    .buttons {
      width: 100%;
      text-align: right;
    }
    &::after {
      @extend .clearfix;
    }
  }
  .comment-list {
    .indent {
      margin-left: 40%;
      margin-bottom: 0.31rem;
      &.indent-1 {
        margin-left: 0;
      }
      &.indent-2 {
        margin-left: 5%;
      }
      &.indent-3 {
        margin-left: 10%;
      }
      &.indent-4 {
        margin-left: 15%;
      }
      &.indent-5 {
        margin-left: 20%;
      }
      &.indent-6 {
        margin-left: 25%;
      }
      &.indent-7 {
        margin-left: 30%;
      }
      &.indent-8 {
        margin-left: 35%;
      }
      &.moder {
        opacity: 0.7;
        background-color: #EEE;
      }
      .avatar {
        float: left;
        width: 2rem;
        margin-right: 0.5rem;
      }
      .username {
        font-size: 1em;
      }
      .info {
        float: left;
        width: calc(100% - 2.5rem);
        .comment .the-text {
          white-space: pre-line;
          margin-bottom: 0.44rem;
        }
        .head {
          p {
            display: inline;
            &.date {
              color: #AAA;
            }
          }
        }
        .switch-edit {
          textarea {
            width: 100%;
          }
        }
        .edit-btns {
          text-align: right;
        }
        .bottom {
          .input-box {
            textarea {
              width: 100%;
            }
          }
          .reply-btn {
            color: #999;
            font-size: 1em;
          }
          .buttons .icons {
            float: right;
            opacity: 0;
            transition: opacity 0.5s ease-in-out 0s;
          }
          .form .buttons {
            text-align: right;
          }
        }
      }
      &::after {
        @extend .clearfix;
      }
      &:hover {
        .info {
          .bottom {
            .buttons .icons {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  textarea {
    display: block;
    box-sizing: padding-box;
    overflow: hidden;
    padding: 0.625rem;
    font-size: 0.875rem;
  }
  .err-msg {
    font-size: 1.125rem;
    background-color: #FF8888;
    padding: 0.625rem;
    color: #FFF;
    text-align: center;
    width: 100%;
    margin-bottom: 0.625rem;
    a {
      color: #BBB;
    }
    border-radius: 0.44rem;
  }
  .ico {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .ico-edit {
    background-position: 0 -72px;
  }
  .ico-delete {
    background-position: -457px 0;
    width: 12px;
  }
  inactive-msg {
    width: 100%;
  }
  &.anonymous {
    width: 100%;
  }
}
.parent_remove_comments{
  position: relative;
}
.delete_comment_item{
  position: relative;
  top:0px;
  right:0px;
  font-size: 18px;
}
.comment_section:last-child, #panel2v .comment_section:last-child {
  border-bottom: none !important;
}
#panel2v-label .tabs-panel .comment_section:last-child{
  border-bottom: none;
}

.vimeo.preview-video {
  position: relative;
  .overlay-box {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: -1;
    color: #FFF;
    background-color: #000;
    transition: opacity 2s;
    overflow: hidden;
    .centering {
      display: table;
      width: 100%;
      height: 100%;
    }
    .wrapper {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      .content {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}

.overlay-mode .vimeo.preview-video .overlay-box,
.vimeo.preview-video .overlay-box.force-show {
  overflow: visible;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  opacity: 0.9;
  z-index: 0;
}

a.btn:not([href]) {
  color: #fff;
  &:hover {
    color: #fff;
  }
  &.btn-light, &.btn-warning {
    color: #212529;
    &:hover {
      color: #212529;
    }
  }
}

.thumb-duration {
  position: relative;
  .duration {
    position: absolute;
    display: inline-block;
    background-color: #000;
    opacity: 0.7;
    padding: 0.02em 0.6em;
    bottom: 5%;
    right: 5%;
    // font-size: 0.7em;
    border-radius: 0.4em;
    color: #FFF;
    z-index: 7;
  }
  &.duration-mini {
    .duration {
      padding: 0.02em 0.3em;
      bottom: 6%;
      right: 12%;
      z-index: 8;
      font-size: 0.6em;
      border-radius: 0.2em;
    }
  }
  img.img-full {
    width: 100%;
  }
  .overlay-item {
    position: absolute;
    top: 0;
    height: 100%;
    transition: background-color 0.5s ease;
    display: inline-block;
    color: #fff;
    &.watched {
    }
    &.overlay-half {
      background-color: rgba(0, 0, 0, 0.7);
      right: 0;
      width: 50%;
      text-align: center;
      padding-top: 2.2em;
      font-size: 0.8em;
      text-transform: uppercase;
      .nr {
        font-size: 1.8em;
      }
    }
    &.overlay-full-hover {
      background-color: rgba(0, 0, 0, 0);
      text-align: center;
      opacity: 0;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 1.5em;
      left: 0;
      width: 100%;
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 1;
      }
      .txt {
        top: calc(50% - 0.8em);
        display: inline-block;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }
  }
}
.instruction-page {
  .playlist-dropdown {
    min-width: 20em;
  }
  .playlist-items {
    .wrapper {
      height: 100%;
      max-height: 35vw;
    }
    .playlist-item:hover {
      text-decoration: none !important;
    }
    li:not(.active) {
      .playlist-item {
        transition: opacity 0.25s ease;
        opacity: 1;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
[y-scrollable-container] {
  overflow-y: auto;
}
.bg-cont-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-black {
  background-color: #000 !important;
}

.notification-block {
  .notif-img {
    max-width: 3em;
  }
  .badge-notif-overlay {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.media-image-container-md {
  width: 10em;
  img {
    width: 100%;
  }
}
.close-button {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.input-right-ico-cont {
  position: relative;
  input {
    padding-right: 2em;
  }
  .right-ctrl-ico {
    display: inline-block;
    position: absolute;
    right: 0.2em;
    top: 50%;
    margin-top: -1em;
  }
}

.search-ico-comp {
  .inp-right-ico {
    right: 10px;
  }
}
.node-tree-quiz {
  .q-wrapper {
    margin: 5em auto;
    width: 30em;
    .prev-question {
      background-color: #EEE;
      opacity: 0.8;
    }
    .current-question {
      position: relative;
      .inline-item {
        display: inline-block;
        vertical-align: middle;
        a, input, i {
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .get-back {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
      }
    }
  }
}
.invisible {
  width: 0;
  height: 0;
  position: fixed;
  left: -100px;
  top: -100px;
  overflow: hidden;
  opacity: 0;
}

.box_watched {
  display: block;
  transition: opacity 1s ease;
  top: 0.3em;
  left: 0.5em;
  text-align: center;
  position: absolute;
  z-index: 8;
  opacity: 1 !important;
  .watched {
    font-size: 0.8em;  // lists need to be able to breathe
    padding: 0.3em; // make room for the circle
    position: relative;
    background: #000;
    color: #fff;
    font-weight: bolder;
    z-index: 8;
    opacity: 0.7;
  }
}
.quiz-panes {
  .bg-img {
    background-image: url("/static/img/quiz_tyler.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .top-section {
      font-size: 1.3em;
      margin-top: 5.5em;
    }
    .mid-section.mob {
      text-align: center;
      width: 100%;
      font-size: 4em;
      top: 50%;
      margin-top: -0.5em;
    }
    .bg-black-shadow {
      background-color: rgba(0,0,0,0.3);
    }
  }
  #answer-list {
    .wrapper {
      vertical-align: middle;
    }
  }
  .answer-pane {
    overflow-y: auto;
    height: calc(100% - 7em);
  }
  .answer-help-ico {
    color: #fff !important;
    right: 0.5em;
    top: 0.2em;
  }
  .start-over {
    left: 0;
    top: 1em;
  }
  .get-back {
    right: 1em;
    top: 1em;
  }
  .main-row {
    .pane {
      // min-height: 50em;
      height: calc(100vh - 3.8em);
    }
    .answer-item {
      background-color: #00a879;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
@media (max-width: 768px) {
  .instruction-page {
    .playlist-items {
      .wrapper {
        max-height: 30em;
      }
    }
  }
  .quiz-panes {
    #answer-list {
      &:before {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle;
      }
    }
    .main-row .pane.bg-img {
      height: 130vw;
      width: 100%;
      .top-section {
        margin-top: 3em;
        font-size: 1em;
      }
      .mid-section.mob {
        font-size: 2em;
        top: 50%;
        margin-top: -0.5em;
      }
    }
    .question {
      font-size: 0.5em;
    }
    .answer-pane {
      overflow-y: visible;
      height: auto;
    }
    .main-row {
      .pane {
        min-height: 0;
        height: auto;
        padding-top: 3em;
        padding-bottom: 3em;
      }
    }
  }
}
.hr-5 {
  height: 5em;
}
.hr-3 {
  height: 3em;
}
.footer-spacer {
  position: relative;
  width: 100%;
  padding: 0.3em;
  font-size: 1.3em;
  height: 2em;
}
.nonmember-notif {
position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  color: #FFF;
  font-weight: bold;
  z-index: 1039;
}
.playlist-only-list {
  .sort_handle {
    float: left;
    margin-top: 3em;
    cursor: grab;
  }
}
.overlay-mid-hover {
  position: relative;
  .overlay-cont {
    opacity: 0;
    display: block;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    top: 0;
    left: 0;
    text-decoration: none;
    font-size: 1.5em;
    &:before {
      content: "";
      display: block;
      height: calc(50% - 0.5em);
    }
  }
  &:hover .overlay-cont {
    opacity: 1;
    background-color: rgba(0,0,0,0.7);
  }
}
@media (max-width: 575px) {  // xs <576px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 60%;
      // bottom: auto;
      // top: 2.5em;
    }
    .box_watched {
      font-size: 60%;
      left: 2em;
    }
    .overlay-watched {
      // opacity: 0;
    }
  }
}
@media (min-width: 576px) {  // sm >=576px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 80%;
      // bottom: auto;
      // top: 2.1vw;
    }
    .box_watched {
      font-size: 80%;
      left: 1.7em;
    }
    .overlay-watched {
      // opacity: 0;
    }
  }
}
@media (min-width: 768px) {  // md >=768px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 50%;
      bottom: auto;
      top: 2.2vw;
      right: 20%;
    }
    .box_watched {
      font-size: 40%;
      top: 10%;
      left: 20%;
    }
    .overlay-watched {
      opacity: 0;
    }
  }
}

@media (min-width: 828px) {  // md >=768px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 50%;
      bottom: auto;
      top: 3.2vw;
      right: 20%;
    }
    .box_watched {
      font-size: 60%;
      top: 10%;
      left: 20%;
    }
  }
}
@media (min-width: 992px) {  // lg >=992px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 70%;
      bottom: auto;
      top: 2.8vw;
    }
    .box_watched {
      font-size: 60%;
      left: 1.7em;
      top: 3%;
    }
  }
}
@media (min-width: 1080px) {  // lg >=992px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 70%;
      bottom: auto;
      top: 3.2vw;
    }
    .box_watched {
      font-size: 60%;
      left: 1.7em;
      top: 3%;
    }
  }
}
@media (min-width: 1200px) {  // xl >=1200px
  .thumb-container.thumb-duration {
    .duration {
      font-size: 70%;
      bottom: auto;
      top: 3.8vw;
      right: 13%;
    }
    .box_watched {
      font-size: 60%;
      left: 1.7em;
      top: 3%;
    }
  }
}
@media (min-width: 1400px) {  // xxl
  .thumb-container.thumb-duration {
    .duration {
      font-size: 80%;
      bottom: auto;
      top: 4.5vw;
    }
    .box_watched {
      font-size: 80%;
      left: 1.7em;
    }
    .overlay-watched {
      opacity: 0.5;
    }
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .gsa-nav {
    .navbar-brand, .navbar-brand.h5-sm {
      font-size: 1rem !important;
    }
    .nav-link {
      font-size: 0.9rem !important;
    }
  }
}
@media (max-width: 991px) {  // lg
  .notif-item .notif-img {
    width: 15% !important;
  }
  .gsa-nav {
    .nav-item > a {
      line-height: 1;
    }
    .dropdown-playlist-cont {
      padding-bottom: 0;
    }
    .mob-force-expand {
      .dropdown-menu {
        display: block;
        background: black;
        color: rgba(255, 255, 255, 0.5);
        .dropdown-header {
          padding-left: 0;
        }
        .dropdown-item {
          padding-left: 0;
          color: rgba(255, 255, 255, 0.5);
          &.is-active {
            color: rgba(255, 255, 255, 1);
          }
          &:hover, &:focus {
            background: black;
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
    }
  }
}
.coach-pane {
  overflow: auto;
}
.coach-list, .coach-popup {
  .face-wrapper {
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      padding-top: 100%;
    }
    .face-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
    }
  }
}
.coach-google-map-wrapper {
  .gmap-coach {
    height: 100%;
  }
}
.mobile-close-menu-hp {
  display: none;
}
.home-page {
  .green {
    color: #00af72!important;
  }
  a {
    text-decoration: none;
  }
  .hp-blocks-wrapper {
    position: relative;
    .hp-blocks {
      position: relative;
      //margin-top: -60px;
      //margin-bottom: 1rem;
      //padding-bottom: 1rem;

      width: 100%;
    }
  }
  .navbar {
    //z-index: 2;
    //height: 60px;
    //background-color: transparent !important;
  }
  header#header {
    padding: 1rem 3rem;
    .header-wrapper {
      .header-title-nav-wrapper {
        display: inline-block;
      }
      .mob-switcher {
        display: none;
      }
      .header-title {
        display: inline-block;
        vertical-align: middle;
        a, .lnk {
          color: black;
          font-size: calc(1.2vw + 1rem);;
        }
      }
      .trial-btn-wrapper {
        display: inline-block;
        vertical-align: middle;
        float: right;
      }
      .header-nav {
        display: inline-block;
        vertical-align: middle;
        .header-nav-list {
          display: inline-block;
          vertical-align: middle;
          a, .lnk {
            display: inline-block;
            vertical-align: middle;
            color: black;
            margin-left: 20px;
            font-size: calc(.12vw + 1rem);
          }
        }
      }
    }
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index:2;
  }
  .hp-blocks {
    .hp-block {
      width: 100%;
      padding: 30px;
    }
    .header {
      font-size: 55px;
      line-height: 65px;
    }
    .sub-header {
      font-size: 18px;
      line-height: 22px;
    }
    .body {
      font-size: 20px;
      line-height: 29px;
    }
    .big-btn {
      padding: .5em 1em;
      line-height: 30px;
      .btn-header {
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
        color: #ffffff;
      }
      .btn-sub {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #9fc4ff;
      }
    }
    .section-title {
      font-size: 35px;
      line-height: 42px;
      font-weight: 500;
    }
    .extend-link {
      color: white;
      &:hover {
        color: #0f6cff;
      }
    }
  }
  .top-section {
    font-size: 1.1rem;
    background: url('/static/img/hp/home_top.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &::before {
      content: "";
      background-color: black;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }

    width: 100%;
    position: relative;
    .header {
      left: 0;
      top: 50%;
      .h-inner {
        max-width: 90%;
      }
    }
  }
  .gsa-system {
    align-items: center;
    color: black;
    .item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      .image {
        width: 300px;
        padding-bottom: 1rem;
      }
    }
  }
  .step-list {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.img-right {
      flex-direction: row-reverse;
      .half-pane {
        &.img-part {
          text-align: left;
        }
        // flex-direction: row-reverse;
      }
    }
    .half-pane {
      &.img-part {
        text-align: right;
        .img-wrapper {
          width: 80%;
          display: inline-block;
          img {
            width: 300px;
            height: auto;
          }
        }
      }
      .text-cont {
        display: inline-block;
        height: 100%;
        :before {
          vertical-align: middle;
          display: inline-block;
          content: "";
          height: 100%;
        }
        .text-wrapper {
          vertical-align: middle;
          display: inline-block;
          .header {
            font-size: 2rem;
            color: #00af72;
          }
          .description {
            font-size: 1.3rem;
          }
        }
      }
      // display: flex;
      // flex-direction: row;
      width: 49.5%;
    }
    &.dark-step {
      background-color: white;
      color: #fff;
      .header {
        color: #fff;
      }
    }
    .text-pane {
      padding: 20px;
    }
  }
  .bottom-block {
    display: flex;
    align-items: center;
    background-image: url('/static/img/hp/home_bottom.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 500px;
    width: 100%;
    .header {
      margin-bottom: 3rem;
      font-size: 3rem;
      color: #fff;
    }
  }
  .community-feedback {
    width: 1024px;
    max-width: 95%;
    display: block;
    margin: 2rem auto;
    .header {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    .feedback-desc {
      margin-top: 1.5rem;
      font-size: 1.2rem;
    }
    .feedback-item {
      text-align: left;
      .thmb {
        .play-overlay {
          display: inline-block;
          text-align: center;
          position: relative;
          .play-wrapper {
            color: #fff;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.8em;
            z-index: 2;
            font-size: 5em;
            opacity: 0.5;
            transition: opacity 0.5s ease;
          }
          &:hover .play-wrapper {
            opacity: 1;
          }
        }
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .become-coach{
    background-color: #111111;
    .section-title {
      font-size: 35px;
      font-weight: 500;
      line-height: 42px;
      color: #00af72;
    }
    .tip-list{
      img {
        margin-bottom: 20px;
      }
    }
  }
  .meet-instructor {
    position: relative;
    &::before{
      content: "";
      background: url('/static/img/hp/MeetYourInstructor.jpg') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      -webkit-mask-repeat:no-repeat;
      -webkit-mask-image: -webkit-gradient(
        linear,
        right bottom,
        left bottom,
        color-stop(0.35,  rgba(0,0,0,1)),
        color-stop(0.60,  rgba(0,0,0,0))
      );

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }

    .fa {
      color: white;
      &.checked {
        color: #ffcb4a;
      }
    }

    .sub-text {
      font-size: 20px;
    }
  }
  .feedbacks {
    background-color: #111111;
    color: white;
    .feedback-item{
      padding-top: 30px;
    }
    .section-name {
      font-size: 16px;
      color: #00af72;
      margin-top: 20px;
    }
  }
  .comments {
    background-color: #111111;
    .section-name {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  .expect-videos {
    background-color: #f0f0f0;
    .section-semi-sub-title {
      font-size: 1.5rem;
    }
  }
  .forum {
    position: relative;
    background: url('/static/img/hp/Forum_back.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    .section-title {
      font-size: 3rem;
      line-height: 52px;
      color: #00af72;
    }

    .section-content {
      font-size: 1.3rem;
      font-weight: 400;
    }
  }
}
.faq-homepage {
  background-color: #f0f0f0;
  .section-semi-sub-title {
    font-size: 1.5rem;
  }
}
.child-inline-block {
  > * {
    display: inline-block;
  }
  &.top > * {
    vertical-align: top;
  }
  &.middle > * {
    vertical-align: middle;
  }
}
.space-ico {
  max-width: calc(100% - 2em);
}

.course-page {
  .lesson-list {
    .lesson-item {
      padding: 0.3em;
      &.active, &:hover {
        background-color: #f0f0f0;
      }
      .lesson-duration {
        line-height: 2em;
      }
    }
  }
  .fixed-height {
    height: 35.5vw;
    &.lesson-block {
      .lesson-list {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  .video-locked {
    .overlay-content {
      background-color: rgba(0, 0, 0, 0.8);
      top: 0;
      left: 0;
      z-index: 2;
      &:before {
        display: inline-block;
        vertical-align: middle;
        content: "";
        height: 100%;
      }
    }
    .v-center {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.affiliate-chart-hint {
  display: inline-block;
  line-height: 2em;
  height: 3.5em;
  margin: 1em;
  padding-right: 1em;
  .clicks-line {
    font-size:2em;
  }
}

@media (min-width: 769px) {
  .home-page {
    .mobile-small {
      display: none !important;
    }
  }
}
@media (max-width: 992px) {  // lg >=992px
  .home-page {
    #navbarSupportedContent {
      position: absolute;
      background-color: rgba(0,0,0,0.7);
      top: 3rem;
      left: 0;
      width: 100%;
      height: auto;
      text-align: left;
      font-size: 1rem;
      z-index: 1040;
      padding-left: 1rem;
      padding-bottom: 1rem;

      .dropdown-menu {
        border: 0 solid transparent !important;
        text-align: left;
        font-size: 1rem;
        background-color: transparent;
        padding-top: 0.5rem;
        margin-top: 0;

        .dropdown-item {
          padding-left: 0;
          padding-right: 0;
          &:hover, &:focus {
            background-color: transparent;
          }
        }
      }
      a.dropdown-item, a.nav-link {
        color: #CCC !important;
        :hover {
          color: #FFF !important;
        }
      }
    }
    .only-desktop {
      display: none !important;
    }
    .hp-blocks {
      .header {
        font-size: 40px;
        line-height: 46px;
      }
      .sub-header {
        font-size: 18px;
        line-height: 22px;
      }
      .body {
        font-size: 16px;
        line-height: 22px;
      }
      .big-btn {
        padding: .5em .75em;
        line-height: 1.5;
        .btn-header {
          font-size: 28px;
          line-height: 32px;
          font-weight: 500;
          color: #ffffff;
        }
        .btn-sub {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #9fc4ff;
        }
      }
      .section-title {
        font-size: 25px;
        line-height: 30px;
        font-weight: 500;
      }
    }
    .top-section {
      text-align: center;
    }
    .meet-instructor {
      &::before {
        background: none;
      }
    }
    .expect-videos {
      .section-semi-sub-title {
        font-size: 1.1rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .home-page {
    .navbar {
      padding: 0.5em 1em;
      .nav-right-ico-mob {
        top: 20px;
      }
    }
    .hp-blocks{
      .hp-block {
        padding: 10px;
        .btn-header {
          font-size: 29px;
          line-height: 32px;
        }
        .header {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
    .step-list {
      height: auto;
      display: block;
      // flex-direction: row;
      // align-items: center;
      &.img-right {
        .half-pane {
          &.img-part {
            text-align: center;
          }
          // flex-direction: row-reverse;
        }
      }
      .half-pane {
        &.img-part {
          text-align: center;
          .img-wrapper {
            width: 90%;
            display: inline-block;
            img {
              width: 100%;
            }
          }
        }
        .text-cont {
          display: block;
          height: auto;
          :before {
            display: none;
          }
          .text-wrapper {
            vertical-align: middle;
            display: block;
          }
        }
        // display: flex;
        // flex-direction: row;
        width: 100%;
      }
      .text-pane {
        padding: 20px;
      }
    }
    .gsa-system {
      .header {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      .subheader {
        font-size: 1.3rem;
      }
    }
    .top-section {
      font-size: 1.1rem;
      .btn.btn-buy {
        font-size: 1rem;
        padding: 0.7rem;
      }
      .header {
        top: 50%;
        font-size: 2rem;
      }
      .btn-wrapper {
        bottom: 10%;
      }
    }
    .bottom-block {
      background-size: auto 100%;
    }
    header#header {
      height: 100px;
      position: absolute;
      padding: 1rem 3rem;
      .header-wrapper {
        .mob-switcher {
          position: absolute;
          top: 6px;
          right: 20px;
          z-index: 1040;
          display: block;
          font-size: 2rem;
          color: #000;
        }
        .header-display {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          transition: opacity 0.5s ease, height 0.5s ease;
          opacity: 0;
          height: 0;
          background-color: #fff;
          &.enable {
            z-index: 1039;
            opacity: 1;
            height: 100%;
          }
        }
        .header-title-nav-wrapper {
          display: block;
        }
        .header-title {
          position: absolute;
          top: 10px;
          left: 20px;
          font-size: 1.5rem;
          z-index: 1040;
          a, .lnk {
            font-size: 1em;
          }
        }
        .trial-btn-wrapper {
          display: block;
          float: none;
          text-align: center;
          margin-top: 20rem;
          transition: margin-top 0.5s ease;
        }
        &.fixme {
          .mob-switcher, .header-title {
            position: fixed;
          }
          .trial-btn-wrapper {
            margin-top: 2rem;
          }
        }
        .header-nav {
          display: block;
          padding-top: 6rem;
          text-align: center;
          .header-nav-list {
            display: block;
            a, .lnk {
              display: block;
              margin-left: 0;
              font-size: 2.2rem;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
    .community-feedback {
      .feedback-desc {
        margin-bottom: 1.5rem;
      }
    }
  }
}
@media (max-width: 400px) {
  .home-page {
    .top-section {
      font-size: 0.8rem;
    }
    .hp-blocks .hp-block{
      padding: 10px;
      .btn-header {
        font-size: 23px;
        line-height: 28px;
      }
    }
    .bottom-block {
      height: 400px;
    }
    .step-list {
      .half-pane {
        .text-cont {
          .text-wrapper .description {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
.btn-view {
  background-color: rgba(0, 168, 121, 1);
  color: #fff;
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: rgba(0, 168, 121, 0.8);
    color: #fff;
  }
}

.btn-buy {
  background-color: #000;
  color: #fff!important;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 3rem;
  transition: background-color 0.5s ease;
  &:hover {
    opacity: .75;
  }
}

.force-events {
  pointer-events: auto !important;
}
.mobile-only {
  display: none !important;
}

.disabled2, .btn-form-sending, [disablebtn=yes] {
  pointer-events: none;
  background-color: #007bff;
  border-color: #007bff;
  opacity: .65;
}
.disablebtn {
  pointer-events: none;
  opacity: .65;
}

.sending-cont {
  display: none;
}
.btn-form-sending {
  .active-cont {
    display: none;
  }
  .sending-cont {
    display: inline-block;
  }
}
@media (max-width: 992px) {
  .mobile-only {
    display: block !important;
  }
  .course-page {
    .fixed-height {
      height: auto;
      &.lesson-block {
        .lesson-list {
          height: auto;
          overflow-y: visible;
        }
      }
    }
  }
}
@media (min-width: 993px) {
  .mobile-only-rev {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .carousel-item {
    min-height: 15rem;
    .carousel-caption {
      left: 5%;
      right: 5%;
    }
  }
  .sm-order-1 {
    order: 1;
  }
  .sm-order-2 {
    order: 2;
  }
  .sm-border-radius {
    border-radius: 0.5em;
  }
}
@media (max-width: 576px) {
  .carousel-item {
    height: 15rem;
    .carousel-caption {
      left: 5%;
      right: 5%;
    }
    .blockquote {
      font-size: 0.85rem;
    }
  }
  .xs-order-1 {
    order: 1;
  }
  .xs-order-2 {
    order: 2;
  }
  .xs-border-radius {
    border-radius: 0.5em;
  }

}
.arrow-down-exp {
  @include arrowdown(0.4em, #FFF, 1em);
}

a.text-decoration-underline {
  text-decoration: underline;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* Video player Custom play button */
.video-play-button {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  /* color: transparent; */
  border: 0;
  height: 100%;
  width: 100%;
}

/* Hover Effects on Card */

.pricing {
  .card {
    border: none;
    border-radius: 2rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

    // Green buy button
    .btn-buy-green {
      background-color: #00ac7d;
    }

    // Green Card for Annual plan
    &.card-green {
      outline: 5px #00ac7d solid!important;

      .btn-buy {
        background-color: #00ac7d;
      }

      .top-mark {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: 40px;
        padding: 0.35rem 1.5rem;

        color: white;
        font-size: 13px;
        font-weight: bold;
        background-color: #00ac7d;

        min-width: 150px;
      }
    }
  }
  hr {
    margin: 1.5rem 0;
  }
  .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }
  .card-price {
    font-size: 3rem;
    margin: 0;
    .period {
      font-size: 0.8rem;
    }
  }
  ul {
    li {
      margin-bottom: 1rem;

      .help-text {
        font-size: 0.7rem;
        display: block;
      }
    }
  }
  .text-muted {
    opacity: 0.7;
  }
}
@media (min-width: 992px) {
  .pricing {
    .card {
      &:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
      }

      ul {
        .help-text {
          margin-left: 2rem;
          max-width: 275px;
        }
      }
      .mt-54px {
        margin-top: 54px!important;
      }
    }
  }
}
.CardField-input-wrapper {
  input {
    background-color: red !important;
    font-size: 3em;
  }
}
.mulligan-slide-cont {
  padding: 0 10%;
  height: 280px;
  &:before {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: "";
  }
  .slide-block {
    display: inline-block;
    vertical-align: middle;
  }
  .img-cont {
    // float: left;
    width: 18%;
    margin-right: 3%;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  .text-cont {
    padding: .5em;
    width: 71.9%;
    // float: left;
    .desc {
    }
    .author {
    }
  }
}
.quiz-carousel {
  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 2em;
    height: 3.25em;
    opacity: 0.5;
  }
  .carousel-control-prev {
    .carousel-control-prev-icon {
      background-image: url("/static/img/arrow_left.png");
    }
  }
  .carousel-control-next {
    .carousel-control-next-icon {
      background-image: url("/static/img/arrow_right.png");
    }
  }
}
@media (max-width: 992px) {
  .mulligan-slide-cont {
    padding: 0 10%;
    height: 280px;
    display: flex;
    flex-direction: column;
    &:before {
      display: none;
    }
    .slide-block {
      display: block;
    }
    .img-cont {
      width: 100%;
      margin-right: 0;
      text-align: center;
      img {
        width: 20%;
        border-radius: 50%;
      }
    }
    .text-cont {
      width: 100%;
      padding: .5em 1em;
    }
  }

}
.z9 {
  z-index: 9;
}

.affiliate-page {
  .calendar-range-cont {
    cursor: pointer;
    padding: 0 0.8em;
    border: 1px solid #ccc;
    width: 18em;
    height: 1.9em;
    line-height: 1.8em;
  }
  .controls-row {
    select {
      height: 1.9em;
    }
  }
  .table-part {
    .amount {
      text-align: right;
    }
  }
  .arr-down-cal {
    right: 0.2em;
    top: calc(50% - 0.5em);
    z-index: -1;
  }
  .input-date {
    width: 15em;
    background-color: transparent;
    border: 1px solid #999;
  }
  .aff-switchers {
    position: absolute;
    right: calc(15px + 1em);
    top: 1em;
    z-index: 99;
    .button-label {
      font-size: 0.8em;
      text-transform: uppercase;
      display: inline-block;
      border: 1px solid #bbb;
      border-radius: 0.2em;
      margin-left: 0.1em;
      text-align: center;
      padding: 0.2em 1em;
      cursor: pointer;
      &.active {
        color: var(--white);
        background-color: var(--primary);
      }
    }
  }
  @media (max-width: 992px) {
    .stat-item.charged {
      .card-title {
        margin-top: 1em;
      }
    }
    .aff-switchers {
    }
  }
  .calendar-executor {
    min-width: 280px;
  }
  .stat-item {
    .card-title {
      font-size: 1.8em;
    }
    .stat-vals {
      font-size: 1.8em;
    }
    .card {
      min-height: 14.2em;
    }
  }
  .table-part {
    &.scrollable {
      overflow-x: auto;
    }
  }
}
.gsa-nav {
  .navbar-brand {
    margin-right: 0.7em;
  }
  .navbar-nav .nav-link {
    padding-right: .4em;
    padding-left: .4em;
  }
}
.announcement {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0.2em 1em;
  a {
    color: inherit;
    text-decoration: underline;
  }
  &.def-bold {
    font-weight: bold;
  }
}
.dropdown-menu.scrollable-dropdown {
  max-height: calc(100vh - 5em);
  overflow-y: auto;
  &.scrollable-dropdown-half {
    max-height: calc(50vh);
  }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none 
}
.float-right {
  float:right
}
.course-thumbnail {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  /* 16:9 Aspect Ratio */

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: inherit;

    /* Maintain text color on hover */
    text-decoration: none;

    /* Remove underline from links */
  }

  img {
    transition: opacity 0.3s ease;

    /* Smooth transition for opacity */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    /* Resize the image to cover the div area */
  }

  &:hover img {
    opacity: 0.7;

    /* Reduce opacity on hover */
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);

    /* Semi-transparent black background */
    color: #fff;
    padding: 10px;
  }

  h5 {
    font-size: 16px;

    /* Default size */
  }

  p {
    font-size: 12px;

    /* Default size */
  }
}

/* Responsive text sizing within .course-thumbnail */

@media (min-width: 768px) {
  /* For tablets and larger devices */

  .course-thumbnail {
    h5 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (min-width: 992px) {
  /* For desktops and larger devices */

  .course-thumbnail {
    h5 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }
  }
}