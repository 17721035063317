@mixin arrowdown($size, $color, $right: 1em) {
    position: absolute;
    border-color: $color transparent transparent;
    border-image: none;
    border-style: solid inset inset;
    border-width: $size $size 0;
    content: "";
    display: block;
    height: 0;
    margin-top: -$size/2;
    right: $right;
    width: 0;
    top: 50%;
}
